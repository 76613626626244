import { doc, getDoc } from "firebase/firestore";

import { userNotFoundErrorMessage } from "../../../../../Classes/errorHandling";
import { auth, db } from "../../../../../Firebase/init";
import { signOut } from "firebase/auth";

const uidKey = "globalUid";
const nameKey = "globalCompName";

export function getControlUid() {
  let cid = localStorage.getItem(uidKey);
  console.log("cuid ", cid);
  if (cid === null) {
    const handle = () => {
      if (window) {
        window.location.replace("/login");
      }
    };

    if (auth.currentUser) {
      signOut(auth).then(handle).catch(handle);
    } else {
      throw new Error("Chyba, před pokračováním je zapotřebí se odhlásit.");
    }
  }
  return cid;
}

export function getControlUidNullable() {
  let cid = localStorage.getItem(uidKey);
  return cid;
}

export function setControlUid(uid) {
  localStorage.setItem(uidKey, uid);
}

export function deleteControlUid() {
  console.log("Deleting uid");
  localStorage.removeItem(uidKey);
}

// Control name

export function getControlName() {
  let cid = localStorage.getItem(nameKey);
  return cid;
}

export function setControlName(name) {
  localStorage.setItem(nameKey, name);
}

// Subuser data
export async function getSubuserData(uid) {
  return new Promise(async (resolve, reject) => {
    try {
      const subRef = doc(db, `subusers`, `${uid}`);
      const docSnap = await getDoc(subRef);
      if (!docSnap.exists()) {
        return reject("No subuser doc found");
      }
      const data = docSnap.data();

      return resolve({ ...data });
    } catch (error) {
      return reject(error);
    }
  });
}

///////////////////////

export async function getUserCustomClaims() {
  return new Promise(async (resolve, reject) => {
    if (!auth || !auth.currentUser) {
      return reject(userNotFoundErrorMessage);
    }
    let idTokenResult = await auth.currentUser.getIdTokenResult(true);
    if (!idTokenResult || !idTokenResult.claims) {
      return reject(userNotFoundErrorMessage);
    }
    return resolve(idTokenResult.claims);
  });
}

export async function reloadUserAuth() {
  if (!auth || !auth.currentUser) {
    throw new Error("No user");
  }
  let idTokenResult = await auth.currentUser.getIdTokenResult(true);
  auth.currentUser.reload();
  if (!idTokenResult || !idTokenResult.claims) {
    throw new Error("No id token");
  }
  return idTokenResult.claims;
}

export function isPermissionsError(error) {
  if (
    error?.code === "permission-denied" ||
    error?.code === "auth/insufficient-permission"
  ) {
    return true;
  }
  return false;
}
