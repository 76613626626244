import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import {
  getFakturaPlatceData,
  getFakturaPlatceTotal,
  getFakturaTotal,
  getZaokrouhleni,
  isRoundable,
} from "./FakturaCalculations";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import OutlineButton from "../../../../Buttons/OutlineButton";
import {
  appDarkBlue,
  navbarBlue,
} from "../../../../../../Design/SourcesJS/themes";
import { currenciesList } from "../../../../../Data/currencies";
import { theme } from "../../../../../../../App/App";
import ContentCutRoundedIcon from "@mui/icons-material/ContentCutRounded";
import React from "react";
import isEqual from "lodash.isequal";

const scrollProps = {
  "&::-webkit-scrollbar": {
    display: { xs: "block", sm: "none" },
    height: "6px",
  },
  "&::-webkit-scrollbar-track": {
    display: { xs: "block", sm: "none" },
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    display: { xs: "block", sm: "none" },
    backgroundColor: "rgba(0,0,0,0.3)",
    outline: "none",
    borderRadius: "16px",
    borderTopLeftRadius: { xs: "0px", sm: "12px" },
    borderTopRightRadius: { xs: "0px", sm: "12px" },
  },
};

function lengthAfterDecimal(num) {
  var parts = num.toString().split(".");
  if (parts.length === 1) {
    return 0;
  }
  return parts[1].length;
}

export function getCountValueClean(stringValue) {
  let value = stringValue;
  value = value.replace(",", ".");
  value = value.replace(/[^\d.-]/g, "");

  value = removeDotsAfterFirst(value);
  value = removeMinusAfterFirst(value);

  /*if (value.startsWith("0") && value.length > 1) {
    value = value.substring(1);
  }

  if (value.startsWith("-0") && value.length > 2) {
    value = value.slice(0, 1) + value.slice(2);
  }*/
  if (value.trim() === "") return "";
  if (value.trim() === "-") return "-";

  if (value.includes(".")) {
    const lad = lengthAfterDecimal(value);
    if (value.endsWith(".")) {
      return value;
    } else if (value.endsWith("0")) {
      if (lad >= 3) {
        return roundToFloatNumber(value, 3);
      } else {
        return value;
      }
    } else {
      return roundToFloatNumber(value, 3);
    }
  } else {
    return roundToFloatNumber(value, 3);
  }
}

function removeMinusAfterFirst(str) {
  let stringValue = str;
  const firstMinusIndex = stringValue.indexOf("-");

  if (firstMinusIndex !== 0) {
    return stringValue.replaceAll("-", "");
  }

  if (firstMinusIndex !== -1) {
    const firstPart = stringValue.substring(0, firstMinusIndex + 1); // Include the first dot
    const restOfTheString = stringValue
      .substring(firstMinusIndex + 1)
      .replace(/\-/g, ""); // Remove subsequent dots
    return firstPart + restOfTheString;
  }

  return stringValue;
}

export function removeDotsAfterFirst(str) {
  const firstDotIndex = str.indexOf(".");

  if (firstDotIndex !== -1) {
    const firstPart = str.substring(0, firstDotIndex + 1); // Include the first dot
    const restOfTheString = str.substring(firstDotIndex + 1).replace(/\./g, ""); // Remove subsequent dots
    return firstPart + restOfTheString;
  }

  return str;
}

export function roundToFloatNumber(num, floatNum = 2) {
  if (num == 0) return 0;
  return +(
    Math.round(parseFloat(num) + Number.EPSILON + `e+${floatNum}`) +
    `e-${floatNum}`
  );
}

export function roundToTwo(num, fixed = false) {
  if (num === 0) return 0;
  const final = +(Math.round(parseFloat(num) + Number.EPSILON + "e+2") + "e-2");
  if (fixed) {
    return final.toFixed(2);
  }
  return final;
}

function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function getNumberWithCurr(number, currIndex = 0) {
  let currData = currenciesList[currIndex];
  if (currData === undefined) {
    currData = currenciesList[0];
  }
  if (currData?.after) {
    return `${number} ${currData.symbol}`;
  } else {
    return `${currData.symbol} ${number}`;
  }
}

export function getNumberVisual(
  number,
  currIndex = 0,
  toFixed = 2,
  withSpaces = true
) {
  if (isNaN(number)) return "–";
  let roundedNum = roundToTwo(number).toFixed(toFixed).toString();
  if (withSpaces) {
    roundedNum = numberWithSpaces(
      roundToTwo(number).toFixed(toFixed).toString()
    ).replace(".", ",");
  } else {
    roundedNum = roundToTwo(number)
      .toFixed(toFixed)
      .toString()
      .replace(".", ",");
  }
  if (currIndex === 0) {
    return `${roundedNum} Kč`;
  }
  return getNumberWithCurr(roundedNum, currIndex);
}

function getExchangeLayout(currIndex, exchangeRates, total) {
  if (currIndex === undefined || !exchangeRates) return <></>;
  let iso = currenciesList[currIndex]?.iso;
  if (iso == "CZK") return <></>;
  let rate = false;
  for (let i = 0; i < exchangeRates.length; i++) {
    if (exchangeRates[i].name == iso) {
      rate = exchangeRates[i].rate;
      break;
    }
  }

  if (rate && total) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ color: "grey", fontSize: "15px", mt: "8px" }}>
          {`1 ${currenciesList[currIndex]?.symbol} = ${roundToFloatNumber(
            rate,
            3
          )
            .toFixed(2)
            .toString()
            .replace(".", ",")} Kč`}
        </Typography>
        <Typography sx={{ color: "grey", fontSize: "15px", mt: "1px" }}>
          {`~ ${numberWithSpaces(
            roundToFloatNumber(total * rate, 3)
              .toFixed(2)
              .toString()
              .replace(".", ",")
          )} Kč`}
        </Typography>
      </Box>
    );
  } else {
    return <></>;
  }
}

function FakturaCalcLayout({
  platce,
  rows,
  dphCarr,
  dphCalc,
  handleSetState,
  currIndex,
  exchangeRates,
}) {
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const data = getFakturaPlatceData(rows, dphCarr, dphCalc);

  function isPlatceLayout() {
    if (!(!data || data.length === 0) || dphCarr === 1) return true;
    return false;
  }

  function getPlatceLayout() {
    const totalPlatce = getFakturaPlatceTotal(data);

    return (
      <>
        {!xs && (
          <TableContainer
            component={Box}
            sx={{
              overflowX: "auto",
              overflowY: "hidden",
              ...scrollProps,
            }}
          >
            <Table
              sx={{ maxWidth: { xs: "300px", sm: "100%" } }}
              aria-label="simple table"
            >
              <TableHead sx={{ borderBottom: `2px solid ${navbarBlue}` }}>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{ pl: "0px !important", border: 0 }}
                  >
                    <Typography variant="inputTitle">Sazba</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ textAlign: "right", mx: "15px", border: 0 }}
                  >
                    <Typography variant="inputTitle">Základ</Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ pr: "0px !important", border: 0 }}
                  >
                    <Typography variant="inputTitle">DPH</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, i) => (
                  <TableRow
                    key={"TableRowCalcL" + i}
                    sx={{
                      border: 0,
                    }}
                  >
                    <TableCell
                      sx={{
                        borderBottom: 0,
                        pl: "0px !important",
                        fontSize: "1rem",
                      }}
                      align="left"
                    >{`${isNaN(row.dph) ? "–" : row.dph} %`}</TableCell>
                    <TableCell
                      sx={{
                        borderBottom: 0,
                        textAlign: "right",
                        mx: "15px",
                        fontSize: "1rem",
                      }}
                      align="center"
                    >
                      {getNumberVisual(row.base, currIndex)}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: 0,
                        pr: "0px !important",
                        fontSize: "1rem",
                      }}
                      align="right"
                    >
                      {getNumberVisual(row.tax, currIndex)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Divider
              sx={{
                background: navbarBlue,
                borderBottomWidth: 2,
              }}
            />
          </TableContainer>
        )}
        <Box>
          {xs && (
            <Typography
              variant="inputTitle"
              sx={{ textAlign: "left", mb: "5px", mt: "3px" }}
            >{`Součet`}</Typography>
          )}
          {xs && (
            <Divider
              sx={{
                minWidth: "100%",
                background: navbarBlue,
                borderBottomWidth: 2,
              }}
            />
          )}
          <Typography
            variant="navHeader"
            sx={{
              pt: "16px",
              pl: "16px",
              fontSize: "20px",
              lineHeight: "20px",
              color: appDarkBlue,
            }}
          >
            {getNumberVisual(totalPlatce, currIndex)}{" "}
          </Typography>
          {getExchangeLayout(currIndex, exchangeRates, totalPlatce)}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {isRoundable(totalPlatce) && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                maxWidth: xs ? "100%" : "auto",
              }}
            >
              <OutlineButton
                white
                filled
                text={"Zaokrouhlit"}
                leftIcon={<ContentCutRoundedIcon />}
                onClick={() => {
                  let zaokr = getZaokrouhleni(
                    totalPlatce,
                    platce,
                    dphCalc,
                    dphCarr,
                    data
                  );
                  //dph, total
                  handleSetState(null, null, {
                    dph: zaokr.dph,
                    total: zaokr.base,
                  });
                }}
                sxProps={{
                  maxWidth: "160px",
                  pl: xs ? "16px" : "45px",
                  mt: "30px",
                }}
              />
            </Box>
          )}
        </Box>
      </>
    );
  }

  const totalNoDph = getFakturaTotal(rows);
  return (
    <>
      {isPlatceLayout() ? (
        <Box sx={{ width: "100%", mt: "60px", mb: "30px" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-end" },
              overflowX: "auto",
              overflowY: "hidden",
              ...scrollProps,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: { xs: "center", sm: "flex-end" },
                overflowX: "auto",
                overflowY: "hidden",
                ...scrollProps,
                textAlign: "right",
                mr: { xs: "0", sm: "0", md: "40px" },
                width: xs ? "100%" : "auto",
              }}
            >
              <Box>
                {platce && dphCarr !== 1 ? (
                  <Box sx={{ mt: "30px" }}>{getPlatceLayout() || <></>}</Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        //     justifyContent: "flex-end",
                        alignItems: "flex-end",
                        alignContent: "flex-end",
                      }}
                    >
                      {xs && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Typography
                            variant="inputTitle"
                            sx={{ textAlign: "left", mb: "5px", mt: "3px" }}
                          >{`Součet`}</Typography>
                        </Box>
                      )}
                      <Divider
                        sx={{
                          minWidth: xs ? "100%" : "200px",
                          background: navbarBlue,
                          borderBottomWidth: 2,
                        }}
                      />
                      <Typography
                        variant="navHeader"
                        sx={{
                          pt: "16px",
                          pl: "16px",
                          fontSize: "20px",
                          lineHeight: "20px",
                          color: appDarkBlue,
                        }}
                      >
                        {getNumberVisual(totalNoDph, currIndex)}
                      </Typography>
                      {getExchangeLayout(currIndex, exchangeRates, totalNoDph)}
                      {isRoundable(totalNoDph) && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            maxWidth: xs ? "100%" : "auto",
                          }}
                        >
                          <OutlineButton
                            white
                            filled
                            text={"Zaokrouhlit"}
                            leftIcon={<ContentCutRoundedIcon />}
                            onClick={() => {
                              let zaokr = getZaokrouhleni(
                                totalNoDph,
                                platce,
                                dphCalc,
                                dphCarr
                              );
                              //dph, total
                              handleSetState(null, null, {
                                dph: 0,
                                total: zaokr,
                              });
                            }}
                            sxProps={{
                              maxWidth: "160px",
                              pl: xs ? "16px" : "45px",
                              mt: "30px",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

export default React.memo(FakturaCalcLayout, (prevProps, nextProps) => {
  const { handleSetState: prevHSS, ...prevCheck } = prevProps;
  const { handleSetState: nextHSS, ...nextCheck } = nextProps;

  return isEqual(prevCheck, nextCheck);
});
