import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setAnalyticsCollectionEnabled,
  setConsent,
} from "firebase/analytics";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";
import { getDatabase } from "firebase/database";
import { getPerformance } from "firebase/performance";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { areCookiesAccepted } from "../Core/Components/Footer/CookieBar";

// Public config data
const firebaseConfig = {
  apiKey: "AIzaSyCQIdD-VWQfM73b7ENpe3LkZvZ0-pKXHyw",
  authDomain: "app.profaktura.cz",
  databaseURL:
    "https://core-profaktura-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "core-profaktura",
  storageBucket: "core-profaktura.appspot.com",
  messagingSenderId: "849698345234",
  appId: "1:849698345234:web:61b3d1b169f954692123cb",
  measurementId: "G-R0NG0L4XRZ",
};

export const app = initializeApp(firebaseConfig);

const PREVIEW_CHANNEL = false;
if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
} else if (PREVIEW_CHANNEL) {
  /*allow appcheck preview channel "appcheck find text" search
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = "xxx-xxxx"; // change exes to code in file*/

  // eslint-disable-next-line
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = "xxx";
}

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    "6LfW6CEqAAAAAAl1YAoHaweMqhF2JWlRL2RdWHwS"
  ),
  isTokenAutoRefreshEnabled: true,
});

// DATABASES
export const db = getFirestore(app);
export const realtimeDb = getDatabase(app);

// AUTHENTICATION
export const auth = getAuth(app);

export function isUser() {
  if (!auth || !auth.currentUser || !auth.currentUser.emailVerified)
    return false;
  return true;
}

// ANALYTICS
const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, true);

export function logAnalytics(name, additional_params = null) {
  if (!additional_params || Object.keys(additional_params).length === 0) {
    logEvent(analytics, name);
  } else {
    logEvent(analytics, name, additional_params);
  }
}

if (window.gtag) {
  window.gtag("config", "G-R0NG0L4XRZ", {
    linker: {
      domains: ["app.profaktura.cz", "profaktura.cz"],
    },
  });
}

// Full default
const fullConsent = {
  ad_storage: "granted",
  ad_user_data: "granted",
  ad_personalization: "granted",
  analytics_storage: "granted",
  functionality_storage: "granted",
  personalization_storage: "granted",
  security_storage: "granted",
};

// Consent Required Always
const partialConsent = {
  ad_storage: "denied",
  ad_user_data: "denied",
  ad_personalization: "denied",
  analytics_storage: "denied",
  functionality_storage: "granted",
  personalization_storage: "granted",
  security_storage: "granted",
};

function sklikAnonymousRetargeting(allowed = false) {
  if (!window?.rc?.retargetingHit) return;
  var retargetingConf = {
    rtgId: 86687,
    consent: allowed ? 1 : 0,
  };
  window.rc.retargetingHit(retargetingConf);
}

export function setDefaultConsent() {
  sklikAnonymousRetargeting(false);
  setConsent(partialConsent);
  if (window?.gtag) {
    window.gtag("consent", partialConsent);
  }
  if (window?.fbq) {
    window.fbq("consent", "revoke");
  }
}

export function setFullConsent() {
  sklikAnonymousRetargeting(true);
  setConsent(fullConsent);
  if (window?.gtag) {
    window.gtag("consent", fullConsent);
  }
  if (window?.fbq) {
    window.fbq("consent", "grant");
  }
}

export function setAnalyticsConsent() {
  if (!areCookiesAccepted()) {
    setDefaultConsent();
  } else {
    setFullConsent();
  }
}

export function logError(error = null) {
  if (!error) {
    logEvent(analytics, "error_caught");
  } else {
    logEvent(analytics, "error_caught", {
      error_message: error?.message || "",
      stack_trace: error?.stack || "",
    });
  }
}

// PERFORMANCE
const perf = getPerformance(app);

// FUNCTIONS
const functions = getFunctions(app, "europe-west1");

const isFunctionsEmulatorON = true;

if (process.env.NODE_ENV === "development" && isFunctionsEmulatorON) {
  console.log("connectFunctionsEmulator");
  connectFunctionsEmulator(functions, "127.0.0.1", 5003);
}

const urls = {
  localBaseUrl: "http://127.0.0.1:5003/core-profaktura/europe-west1/",
  deployedBaseUrl: "https://europe-west1-core-profaktura.cloudfunctions.net/",
};
const callableOptions = {
  limitedUseAppCheckTokens: true,
};

const initCallFunction = (name, options = {}) =>
  httpsCallable(functions, name, {
    ...callableOptions,
    ...options,
  });

export function getFunctionsUrl(end = "") {
  if (process.env.NODE_ENV === "development" && isFunctionsEmulatorON) {
    return urls.localBaseUrl + end;
  } else {
    return urls.deployedBaseUrl + end;
  }
}

const createHandler = (data = {}, action, handlerFunction) =>
  typeof data === "string"
    ? handlerFunction({ action: data })
    : handlerFunction({ action, ...data });

// PUBLIC VIEW FAKTURA
export const getFakturaByParams = initCallFunction("getFakturaByParams");
export const sendFaktura = initCallFunction("sendFaktura");
// Signups
const signupHandlerInit = initCallFunction("signupHandler");
export const signupHandler = (data, action) =>
  createHandler(data, action, signupHandlerInit);
// Account
export const sendSupportMessage = initCallFunction("sendSupportMessage");
export const setTutorial = initCallFunction("setTutorial");
export const saveVychoziUdaje = initCallFunction("saveVychoziUdaje");
// Rejstriky
export const getAresFromIC = initCallFunction("getAresFromIC");
export const getInsolvenceData = initCallFunction("getInsolvenceData");
export const getCnbData = initCallFunction("getCnbData");
export const getSpolehlivostData = initCallFunction("getSpolehlivostData");
// Subusers
const subusersHandlerInit = initCallFunction("subusersHandler");
export const subusersHandler = (data, action) =>
  createHandler(data, action, subusersHandlerInit);
// SMTP
export const sendFakturaEmail = initCallFunction("sendFakturaEmail");
// Account
export const disableAccount = initCallFunction("disableAccount");
export const requestEnableAccount = initCallFunction("requestEnableAccount");
export const reenableAccount = initCallFunction("reenableAccount");
// Ad subscription
export const getAdSubscriptionInfo = initCallFunction("getAdSubscriptionInfo");
export const changeAdSubscriptionInfo = initCallFunction(
  "changeAdSubscriptionInfo"
);
// PAYMENTS
export const paymentCreate = initCallFunction("paymentCreate");
export const paymentState = initCallFunction("paymentState");
export const changeRecurrenceState = initCallFunction("changeRecurrenceState");
export const getSubsInvoice = initCallFunction("getSubsInvoice");

// STORAGE
export const storage = getStorage(app);

// REMOTE CONFIG
export const remoteConfig = getRemoteConfig(app);
/*
3600000 - for testing
21600000 - for publish (6h)
*/
remoteConfig.settings.minimumFetchIntervalMillis = 21600000;
